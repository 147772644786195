import Vue from 'vue'
import Router from 'vue-router'

import config from './config/config'
import Auth from './views/Auth.vue'

const routesBase = [
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "articles" */ './views/Articles.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ './views/Contact.vue')
  },
  {
    path: '/covid/:page?/:id?',
    name: 'Covid',
    component: () => import(/* webpackChunkName: "covid" */ './views/Covid.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ './views/Events.vue')
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: () => import(/* webpackChunkName: "events" */ './views/Event.vue')
  },
  {
    path: '/extsource/:id',
    name: 'Extsource',
    component: () => import(/* webpackChunkName: "extsource" */ './views/Extsource.vue')
  },
  {
    path: '/extsources',
    name: 'Extsources',
    component: () => import(/* webpackChunkName: "extsources" */ './views/Extsources.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import(/* webpackChunkName: "Lega" */ './views/Legal.vue')
  },
  {
    path: '/ip',
    name: 'Ip',
    component: () => import(/* webpackChunkName: "ip" */ './views/Ip.vue')
  },
  {
    path: '/mardashboard',
    name: 'MarDashboard',
    component: () => import(/* webpackChunkName: "mardashboard" */ './views/MarDashboard.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ './views/News.vue')
  },
  {
    path: '/newsadvancedfilter',
    name: 'NewsAdvancedFilter',
    component: () => import(/* webpackChunkName: "newsadvancedfilter" */ './views/NewsAdvancedFilter.vue')
  },
  {
    path: '/newscategory/:category',
    name: 'NewsCategory',
    component: () => import(/* webpackChunkName: "newscategory" */ './views/NewsCategory.vue')
  },
  {
    path: '/newsitem/:id',
    name: 'NewsItem',
    component: () => import(/* webpackChunkName: "newsitem" */ './views/NewsItem.vue')
  },
  {
    path: '/newssectors/:sector',
    name: 'NewsSectors',
    component: () => import(/* webpackChunkName: "newssectors" */ './views/NewsSectors.vue')
  },
  {
    path: '/news/tag/:id', // this is for backward compatibility before tags changed name to types
    component: () => import(/* webpackChunkName: "newstag" */ './views/NewsTag.vue')
  },
  {
    path: '/news/type/:id',
    name: 'NewsTag',
    component: () => import(/* webpackChunkName: "newstag" */ './views/NewsTag.vue')
  },
  {
    path: '/regbite/:id',
    name: 'Regbite',
    component: () => import(/* webpackChunkName: "regbite" */ './views/Regbite.vue')
  },
  {
    path: '/regbites',
    name: 'Regbites',
    component: () => import(/* webpackChunkName: "regbites" */ './views/Regbites.vue')
  },
  {
    path: '/regulator/:code',
    name: 'Regulator',
    component: () => import(/* webpackChunkName: "regulator" */ './views/Regulator.vue')
  },
  {
    path: '/regulators',
    name: 'Regulators',
    component: () => import(/* webpackChunkName: "regulators" */ './views/Regulators.vue')
  },
  {
    path: '/search/:q',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ './views/Search.vue')
  },
  {
    path: '/signals',
    name: 'Signals',
    component: () => import(/* webpackChunkName: "signals" */ './views/Signals.vue')
  },
  {
    path: '/snapshot',
    name: 'Snapshot',
    component: () => import(/* webpackChunkName: "snapshot" */ './views/Snapshot.vue')
  },
  {
    path: '/sources',
    name: 'Sources',
    component: () => import(/* webpackChunkName: "sources" */ './views/Sources.vue')
  },
  {
    path: '/RecentActivity',
    name: 'RecentActivity',
    component: () => import(/* webpackChunkName: "RecentActivity" */ './views/RecentActivity.vue')
  },
  {
    path: '/risk/:id',
    name: 'Risk',
    component: () => import(/* webpackChunkName: "risk" */ './views/Risk.vue')
  },
  {
    path: '/risks',
    name: 'Risks',
    component: () => import(/* webpackChunkName: "risks" */ './views/Risks.vue')
  },
  {
    path: '/topic/:id',
    name: 'Topic',
    component: () => import(/* webpackChunkName: "topic" */ './views/Topic.vue')
  },
  {
    path: '/topics',
    name: 'Topics',
    component: () => import(/* webpackChunkName: "topics" */ './views/Topics.vue')
  },
  {
    path: '/topics-personalise',
    name: 'TopicsPersonalise',
    component: () => import(/* webpackChunkName: "topicspersonalise" */ './views/TopicsPersonalise.vue')
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import(/* webpackChunkName: "start" */ './views/Start.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "start" */ './views/Start.vue')
  }
]

const routesExternal = [
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "settings" */ './views/About.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue')
  }
]

const routesInternal = [
  {
    path: '/alert/add',
    name: 'AlertAdd',
    component: () => import(/* webpackChunkName: "alertadd" */ './views/AlertAdd.vue')
  },
  {
    path: '/alert/edit/:id',
    name: 'AlertEdit',
    component: () => import(/* webpackChunkName: "alertedit" */ './views/AlertEdit.vue')
  },
  {
    path: '/alert/:id',
    name: 'Alert',
    component: () => import(/* webpackChunkName: "alert" */ './views/Alert.vue')
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import(/* webpackChunkName: "alerts" */ './views/Alerts.vue')
  },
  {
    path: '/apps/analytics',
    component: () => import(/* webpackChunkName: "appAnalyticsLayout" */ './views/apps/analytics/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppAnalytics',
        component: () => import(/* webpackChunkName: "appAnalytics" */ './views/apps/analytics/Loading.vue')
      },
      {
        path: 'start',
        name: 'AppAnalyticsStart',
        component: () => import(/* webpackChunkName: "appAnalyticsStart" */ './views/apps/analytics/Start.vue')
      },
      {
        path: 'bestexecution',
        name: 'AppAnalyticsBestExecution',
        component: () => import(/* webpackChunkName: "appAnalyticsBestExecution" */ './views/apps/analytics/BestExecution.vue')
      }
    ]
  },
  {
    path: '/apps/importing',
    component: () => import(/* webpackChunkName: "appImportingLayout" */ './views/apps/importing/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppImporting',
        component: () => import(/* webpackChunkName: "appImportingLoading" */ './views/apps/importing/Loading.vue')
      },
      {
        path: 'main',
        name: 'AppImportingMain',
        component: () => import(/* webpackChunkName: "appImportingMain" */ './views/apps/importing/Main.vue')
      }
    ]
  },
  {
    path: '/apps/obligations',
    component: () => import(/* webpackChunkName: "appObligationsLayout" */ './views/apps/obligations/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppObligations',
        component: () => import(/* webpackChunkName: "appObligationsLoading" */ './views/apps/obligations/Loading.vue')
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "appObligationsMain" */ './views/apps/obligations/Main.vue'),
        children: [
          {
            path: 'start',
            name: 'AppObligationsStart',
            component: () => import(/* webpackChunkName: "appObligationsStart" */ './views/apps/obligations/main/Start.vue')
          },
          {
            path: 'dashboard',
            name: 'AppObligationsDashboard',
            component: () => import(/* webpackChunkName: "appObligationsDashboard" */ './views/apps/obligations/main/Dashboard.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/apps/reporting',
    component: () => import(/* webpackChunkName: "appReportingLayout" */ './views/apps/reporting/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppReporting',
        component: () => import(/* webpackChunkName: "appReportingLoading" */ './views/apps/reporting/Loading.vue')
      },
      {
        path: 'main',
        name: 'AppReportingMain',
        component: () => import(/* webpackChunkName: "appReportingMain" */ './views/apps/reporting/Main.vue')
      }
    ]
  },
  {
    path: '/apps/:appid',
    component: () => import(/* webpackChunkName: "appUtLayout" */ './views/apps/ut/Layout.vue'),
    children: [
      {
        path: '',
        name: 'AppUT',
        component: () => import(/* webpackChunkName: "appUtStart" */ './views/apps/ut/Start.vue')
      },
      {
        path: 'controls',
        name: 'AppUTControls',
        component: () => import(/* webpackChunkName: "appUtControls" */ './views/apps/ut/Controls.vue')
      },
      {
        path: 'control/:id',
        name: 'AppUTControl',
        component: () => import(/* webpackChunkName: "appUtControl" */ './views/apps/ut/Control.vue')
      },
      {
        path: 'diagram',
        name: 'AppUTDiagram',
        component: () => import(/* webpackChunkName: "appUtDiagram" */ './views/apps/ut/Diagram.vue')
      },
      {
        path: 'editor',
        name: 'AppUTEditor',
        component: () => import(/* webpackChunkName: "appUtEditor" */ './views/apps/ut/Editor.vue')
      },
      {
        path: 'export',
        name: 'AppUTExport',
        component: () => import(/* webpackChunkName: "appUtexport" */ './views/apps/ut/Export.vue')
      },
      {
        path: 'presentation/:id?',
        name: 'AppUTPresentation',
        component: () => import(/* webpackChunkName: "appUtPresentation" */ './views/apps/ut/Presentation.vue')
      },
      {
        path: 'timelines',
        name: 'AppUTTimeline',
        component: () => import(/* webpackChunkName: "appUtTimeline" */ './views/apps/ut/Timeline.vue')
      }
    ]
  },
  {
    path: '/articles/list',
    name: 'ArticlesList',
    component: () => import(/* webpackChunkName: "articleslist" */ './views/ArticlesList.vue')
  },
  {
    path: '/articles/report',
    name: 'ArticlesReport',
    component: () => import(/* webpackChunkName: "articlesreport" */ './views/ArticlesReport.vue')
  },
  {
    path: '/assetclasses',
    name: 'Assetclasses',
    component: () => import(/* webpackChunkName: "assetclasses" */ './views/Assetclasses.vue')
  },
  {
    path: '/assurance',
    name: 'Assurance',
    component: () => import(/* webpackChunkName: "assurance" */ './views/Assurance.vue')
  },
  {
    path: '/auth/:action?',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/case/:id',
    name: 'Case',
    component: () => import(/* webpackChunkName: "case" */ './views/Case.vue')
  },
  {
    path: '/cases',
    name: 'Cases',
    component: () => import(/* webpackChunkName: "cases" */ './views/Cases.vue')
  },
  {
    path: '/comm/add',
    name: 'CommAdd',
    component: () => import(/* webpackChunkName: "commadd" */ './views/CommAdd.vue')
  },
  {
    path: '/comm/edit/:id',
    name: 'CommEdit',
    component: () => import(/* webpackChunkName: "commedit" */ './views/CommEdit.vue')
  },
  {
    path: '/comm/:id',
    name: 'Comm',
    component: () => import(/* webpackChunkName: "comm" */ './views/Comm.vue')
  },
  {
    path: '/comms',
    name: 'Comms',
    component: () => import(/* webpackChunkName: "comms" */ './views/Comms.vue')
  },
  {
    path: '/controls/about',
    name: 'ControlAbout',
    component: () => import(/* webpackChunkName: "controlabout" */ './views/controls/About.vue')
  },
  {
    path: '/controls/control/:id',
    name: 'Control',
    component: () => import(/* webpackChunkName: "control" */ './views/controls/Control.vue')
  },
  {
    path: '/controls/controls',
    name: 'Controls',
    component: () => import(/* webpackChunkName: "controls" */ './views/controls/Controls.vue')
  },
  {
    path: '/coverage',
    name: 'Coverage',
    component: () => import(/* webpackChunkName: "coverage" */ './views/Coverage.vue')
  },
  {
    path: '/riskcomponent/:id',
    name: 'Riskcomponent',
    component: () => import(/* webpackChunkName: "riskcomponent" */ './views/decomp/Riskcomponent.vue')
  },
  {
    path: '/riskcomponents',
    name: 'Riskcomponents',
    component: () => import(/* webpackChunkName: "riskcomponents" */ './views/decomp/Riskcomponents.vue')
  },
  {
    path: '/controls/risks',
    name: 'ControlRisks',
    component: () => import(/* webpackChunkName: "controlrisks" */ './views/controls/Risks.vue')
  },
  {
    path: '/controls/statuses',
    name: 'ControlStatuses',
    component: () => import(/* webpackChunkName: "controlstatuses" */ './views/controls/Statuses.vue')
  },
  {
    path: '/controls/themes',
    name: 'ControlThemes',
    component: () => import(/* webpackChunkName: "controlthemes" */ './views/controls/Themes.vue')
  },
  {
    path: '/extsource/add',
    name: 'ExtsourceAdd',
    component: () => import(/* webpackChunkName: "extsourceadd" */ './views/ExtsourceAdd.vue')
  },
  {
    path: '/extsource/edit/:id',
    name: 'ExtsourceEdit',
    component: () => import(/* webpackChunkName: "extsourceedit" */ './views/ExtsourceEdit.vue')
  },
  {
    path: '/event/add',
    name: 'EventAdd',
    component: () => import(/* webpackChunkName: "eventadd" */ './views/EventAdd.vue')
  },
  {
    path: '/event/edit/:id',
    name: 'EventEdit',
    component: () => import(/* webpackChunkName: "eventedit" */ './views/EventEdit.vue')
  },
  {
    path: '/faqs/about',
    name: 'FaqAbout',
    component: () => import(/* webpackChunkName: "faqabout" */ './views/faqs/About.vue')
  },
  {
    path: '/faqs/faq/:id',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ './views/faqs/Faq.vue')
  },
  {
    path: '/faqs/faqs',
    name: 'Faqs',
    component: () => import(/* webpackChunkName: "controls" */ './views/faqs/Faqs.vue')
  },
  {
    path: '/form/:model/edit/:id',
    name: 'FormEdit',
    component: () => import(/* webpackChunkName: "formedit" */ './views/standard/FormNew.vue')
  },
  {
    path: '/form/:model/new',
    name: 'FormNew',
    component: () => import(/* webpackChunkName: "formnew" */ './views/standard/FormNew.vue')
  },
  {
    path: '/impact/about',
    name: 'ImpactAbout',
    component: () => import(/* webpackChunkName: "impactabout" */ './views/impact/About.vue')
  },
  {
    path: '/impact/expert/:id',
    name: 'ImpactExpert',
    component: () => import(/* webpackChunkName: "impactexpert" */ './views/impact/Expert.vue')
  },
  {
    path: '/impact/experts',
    name: 'ImpactExperts',
    component: () => import(/* webpackChunkName: "impactexperts" */ './views/impact/Experts.vue')
  },
  {
    path: '/impact/initiative/:id',
    name: 'ImpactInitiative',
    component: () => import(/* webpackChunkName: "impactinitiative" */ './views/impact/Initiative.vue')
  },
  {
    path: '/impact/initiative/:id/log',
    name: 'ImpactInitiativeLog',
    component: () => import(/* webpackChunkName: "impactinitiativelog" */ './views/impact/InitiativeLog.vue')
  },
  {
    path: '/impact/initiatives',
    name: 'ImpactInitiatives',
    component: () => import(/* webpackChunkName: "impactinitiatives" */ './views/impact/Initiatives.vue')
  },
  {
    path: '/impact/obligation/:id',
    name: 'ImpactObligation',
    component: () => import(/* webpackChunkName: "impactobligation" */ './views/impact/Obligation.vue')
  },
  {
    path: '/impact/reporting',
    name: 'ImpactReporting',
    component: () => import(/* webpackChunkName: "impactreporting" */ './views/impact/Reporting.vue')
  },
  {
    path: '/impact/topic/:id',
    name: 'ImpactTopic',
    component: () => import(/* webpackChunkName: "impacttopic" */ './views/impact/Topic.vue')
  },
  {
    path: '/impact/topics',
    name: 'ImpactTopics',
    component: () => import(/* webpackChunkName: "impacttopics" */ './views/impact/Topics.vue')
  },
  {
    path: '/impactstatus',
    name: 'Impactstatus',
    component: () => import(/* webpackChunkName: "impactstatus" */ './views/Impactstatus.vue')
  },
  {
    path: '/list/:model',
    name: 'List',
    component: () => import(/* webpackChunkName: "list" */ './views/List.vue')
  },
  {
    path: '/logincognitoauth/:action?',
    name: 'LoginCognitoAuth',
    component: () => import(/* webpackChunkName: "list" */ './views/LoginCognitoAuth.vue')
  },
  {
    path: '/migrations',
    name: 'Migrations',
    component: () => import(/* webpackChunkName: "migrations" */ './views/Migrations.vue')
  },
  {
    path: '/news/aitag/:id',
    name: 'NewsAiTag',
    component: () => import(/* webpackChunkName: "newsaitag" */ './views/NewsAiTag.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "notifications" */ './views/Notifications.vue')
  },
  {
    path: '/notificationsexternal',
    name: 'NotificationsExternal',
    component: () => import(/* webpackChunkName: "notificationsexternal" */ './views/NotificationsExternal.vue')
  },
  {
    path: '/person/:id',
    name: 'Person',
    component: () => import(/* webpackChunkName: "person" */ './views/Person.vue')
  },
  {
    path: '/persons',
    name: 'Persons',
    component: () => import(/* webpackChunkName: "persons" */ './views/Persons.vue')
  },
  {
    path: '/persontypes',
    name: 'Persontypes',
    component: () => import(/* webpackChunkName: "persontypes" */ './views/Persontypes.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ './views/Projects.vue')
  },
  {
    path: '/ptt',
    name: 'Ptt',
    component: () => import(/* webpackChunkName: "ptt" */ './views/Ptt.vue')
  },
  {
    path: '/publication/add',
    name: 'PublicationAdd',
    component: () => import(/* webpackChunkName: "publicationadd" */ './views/PublicationAdd.vue')
  },
  {
    path: '/producttypes',
    name: 'Producttypes',
    component: () => import(/* webpackChunkName: "producttypes" */ './views/Producttypes.vue')
  },
  {
    path: '/regdecomp/about',
    name: 'RegDecompAbout',
    component: () => import(/* webpackChunkName: "RegDecompAbout" */ './views/decomp/About.vue')
  },
  {
    path: '/regdecomp/entities',
    name: 'RegDecompEntities',
    component: () => import(/* webpackChunkName: "RegDecompEntities" */ './views/decomp/Entities.vue')
  },
  {
    path: '/regdecomp/functions',
    name: 'RegDecompFunctions',
    component: () => import(/* webpackChunkName: "RegDecompFunctions" */ './views/decomp/Functions.vue')
  },
  {
    path: '/regdecomp/journal',
    name: 'RegDecompJournal',
    component: () => import(/* webpackChunkName: "RegDecompJournal" */ './views/decomp/Journal.vue')
  },
  {
    path: '/regdecomp/obligation/:id',
    name: 'RegDecompObligation',
    component: () => import(/* webpackChunkName: "regDecompObligations" */ './views/decomp/Obligation.vue')
  },
  {
    path: '/regdecomp/obligations',
    name: 'RegDecompObligations',
    component: () => import(/* webpackChunkName: "regDecompObligations" */ './views/decomp/Obligations.vue')
  },
  {
    path: '/regdecomp/pdf',
    name: 'RegDecompPDF',
    component: () => import(/* webpackChunkName: "RegDecompPDF" */ './views/decomp/PDF.vue')
  },
  {
    path: '/regdecomp/regulations',
    name: 'RegDecompRegulations',
    component: () => import(/* webpackChunkName: "RegDecompRegulations" */ './views/decomp/Regulations.vue')
  },
  {
    path: '/regdecomp/regulation/:id',
    name: 'RegDecompRegulation',
    component: () => import(/* webpackChunkName: "RegDecompRegulations" */ './views/decomp/Regulation.vue')
  },
  {
    path: '/regdecomp/requirements',
    name: 'RegDecompRequirements',
    component: () => import(/* webpackChunkName: "RegDecompRequirements" */ './views/decomp/Requirements.vue')
  },
  {
    path: '/regdecomp/static',
    name: 'RegDecompStatic',
    component: () => import(/* webpackChunkName: "RegDecompStatic" */ './views/decomp/Static.vue')
  },
  {
    path: '/regdecomp/workspace/:id',
    name: 'RegDecompWorkspace',
    component: () => import(/* webpackChunkName: "regDecompWorkspace" */ './views/decomp/Workspace.vue')
  },
  {
    path: '/regbite/add',
    name: 'RegbiteAdd',
    component: () => import(/* webpackChunkName: "regbiteadd" */ './views/RegbiteAdd.vue')
  },
  {
    path: '/regbite/edit/:id',
    name: 'RegbiteEdit',
    component: () => import(/* webpackChunkName: "regbiteedit" */ './views/RegbiteEdit.vue')
  },
  {
    path: '/regtopicgroups',
    name: 'Regtopicgroups',
    component: () => import(/* webpackChunkName: "regtopicgroups" */ './views/Regtopicgroups.vue')
  },
  {
    path: '/regulator/add',
    name: 'RegulatorAdd',
    component: () => import(/* webpackChunkName: "regulatoradd" */ './views/RegulatorAdd.vue')
  },
  {
    path: '/regulator/edit/:id',
    name: 'RegulatorEdit',
    component: () => import(/* webpackChunkName: "regulatoredit" */ './views/RegulatorEdit.vue')
  },
  {
    path: '/relationships',
    name: 'Relationships',
    component: () => import(/* webpackChunkName: "relationships" */ './views/Relationships.vue')
  },
  {
    path: '/reportingframework',
    name: 'Reportingframework',
    component: () => import(/* webpackChunkName: "reportingframework" */ './views/Reportingframework.vue')
  },
  {
    path: '/reportingfrequency',
    name: 'Reportingfrequency',
    component: () => import(/* webpackChunkName: "reportingfrequency" */ './views/Reportingfrequency.vue')
  },
  {
    path: '/risk/add',
    name: 'RiskAdd',
    component: () => import(/* webpackChunkName: "riskadd" */ './views/RiskAdd.vue')
  },
  {
    path: '/risk/edit/:id',
    name: 'RiskEdit',
    component: () => import(/* webpackChunkName: "riskedit" */ './views/RiskEdit.vue')
  },
  {
    path: '/rricadmin',
    name: 'RRICAdmin',
    component: () => import(/* webpackChunkName: "rricadmin" */ './views/RRICAdmin.vue')
  },
  {
    path: '/source/add',
    name: 'SourceAdd',
    component: () => import(/* webpackChunkName: "sourceadd" */ './views/SourceAdd.vue')
  },
  {
    path: '/source/:id',
    name: 'Source',
    component: () => import(/* webpackChunkName: "source" */ './views/Source.vue')
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import(/* webpackChunkName: "stats" */ './views/Stats.vue')
  },
  {
    path: '/signoutcognitoauth',
    name: 'SignOutCognitoAuth',
    component: () => import(/* webpackChunkName: "stats" */ './views/SignOutCognitoAuth.vue')
  },
  {
    path: '/topic/add',
    name: 'TopicAdd',
    component: () => import(/* webpackChunkName: "topicadd" */ './views/TopicAdd.vue')
  },
  {
    path: '/topic/edit/:id',
    name: 'TopicEdit',
    component: () => import(/* webpackChunkName: "topicedit" */ './views/TopicEdit.vue')
  },
  {
    path: '/ugroups',
    name: 'UGroups',
    component: () => import(/* webpackChunkName: "ugroups" */ './views/UGroups.vue')
  },
  {
    path: '/usergroups',
    name: 'UserGroups',
    component: () => import(/* webpackChunkName: "usergroups" */ './views/UserGroups.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ './views/Users.vue')
  },
  {
    path: '/workflow',
    name: 'Workflow',
    component: () => import(/* webpackChunkName: "workflow" */ './views/Workflow.vue')
  },
  {
    path: '/workflow/articles',
    name: 'WorkflowArticles',
    component: () => import(/* webpackChunkName: "workflowarticles" */ './components/workflow/Articles.vue')
  },
  {
    path: '/workflow/articles/dashboard',
    name: 'WorkflowArticlesDashboard',
    component: () => import(/* webpackChunkName: "workflowarticlesdashboard" */ './components/workflow/article/Dashboard.vue')
  },
  {
    path: '/workflow/articles/diagram',
    name: 'WorkflowArticlesDiagram',
    component: () => import(/* webpackChunkName: "workflowarticlesdiagram" */ './components/workflow/article/Diagram.vue')
  },
  {
    path: '/workflow/articles/deleted',
    name: 'WorkflowArticlesDeleted',
    component: () => import(/* webpackChunkName: "workflowarticlesdeleted" */ './components/workflow/article/Deleted.vue')
  },
  {
    path: '/workflow/articles/journal',
    name: 'WorkflowArticlesJournal',
    component: () => import(/* webpackChunkName: "workflowarticlesjournal" */ './components/workflow/article/Journal.vue')
  },
  {
    path: '/workflow/articles/personal',
    name: 'WorkflowArticlesPersonal',
    component: () => import(/* webpackChunkName: "workflowarticlespersonal" */ './components/workflow/article/Personal.vue')
  },
  {
    path: '/workflow/articles/published',
    name: 'WorkflowArticlesPublished',
    component: () => import(/* webpackChunkName: "workflowarticlespublished" */ './components/workflow/article/Published.vue')
  },
  {
    path: '/workflow/articles/starrednews',
    name: 'WorkflowArticlesStarredNews',
    component: () => import(/* webpackChunkName: "workflowarticlespersonal" */ './components/workflow/article/StarredNews.vue')
  },
  {
    path: '/workflow/article/:id',
    name: 'WorkflowArticle',
    component: () => import(/* webpackChunkName: "workflowarticle" */ './components/workflow/Article.vue')
  }
]

let routes = routesBase

if (config.BUILD === 'full') {
  routes = routes.concat(routesInternal)
}

if (config.BUILD === 'external') {
  routes = routes.concat(routesExternal)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: routes
})
