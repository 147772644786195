import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    appData: null,
    appDataToml: null,
    appObligationsData: [],
    appObligationFields: [],
    article: null,
    articles: [],
    countrySelected: [],
    entitySelected: [],
    expertsSelected: [],
    initiatives: [],
    key: null,
    regulatorSelected: [],
    reportingframeworkSelected: [],
    sessionShort: 0,
    showInitiatives: [],
    statusSelected: [],
    topicSelected: [],
    triggerArticleEditorRefresh: null,
    triggerArticleSave: null,
    user: {},
    username: '',
    userState: '',
    windowBottom: false,
    workflowStage: 0
  },
  mutations: {
    setAppData (state, payload) {
      state.appData = payload
    },
    setAppDataToml (state, payload) {
      state.appDataToml = payload
    },
    setAppObligationsData (state, payload) {
      state.appObligationsData = payload
    },
    setAppObligationsFields (state, payload) {
      state.appObligationFields = payload
    },
    setArticle (state, payload) {
      state.article = payload
    },
    setArticles (state, payload) {
      state.articles = payload
    },
    setCountrySelected (state, payload) {
      state.countrySelected = payload
    },
    setEntitySelected (state, payload) {
      state.entitySelected = payload
    },
    setExpertsSelected (state, payload) {
      state.expertsSelected = payload
    },
    setInitiatives (state, payload) {
      state.initiatives = payload
    },
    setKey (state, payload) {
      state.key = payload
    },
    setRegulatorSelected (state, payload) {
      state.regulatorSelected = payload
    },
    setReportingframeworkSelected (state, payload) {
      state.reportingframeworkSelected = payload
    },
    setShowInitiatives (state, payload) {
      state.showInitiatives = payload
    },
    setStatusSelected (state, payload) {
      state.statusSelected = payload
    },
    setTopicSelected (state, payload) {
      state.topicSelected = payload
    },
    setTriggerArticleEditorRefresh (state, payload) {
      state.triggerArticleEditorRefresh = payload
    },
    setTriggerArticleSave (state, payload) {
      state.triggerArticleSave = payload
    },
    setSessionShort (state, payload) {
      state.sessionShort = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setUsername (state, payload) {
      state.username = payload
    },
    setUserState (state, payload) {
      state.userState = payload
    },
    setWindowBottom (state, payload) {
      state.windowBottom = payload
    },
    setWorkflowStage (state, payload) {
      state.workflowStage = payload
    }
  }
})

export default store
