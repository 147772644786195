import { AccessControl } from 'accesscontrol'
// This is actually how the grants are maintained internally.
const grantsObject = {
  appalgotradingvisitor: {
    appalgotrading: {
      'read:any': ['*']
    }
  },
  appanalyticsdemovisitor: {
    appanalyticsdemo: {
      'read:any': ['*']
    }
  },
  appobligationsvisitor: {
    appobligations: {
      'read:any': ['*']
    }
  },
  appreportingvisitor: {
    appreporting: {
      'read:any': ['*']
    }
  },
  apputvisitor: {
    apput: {
      'read:any': ['*']
    }
  },
  articleCreator: {
    article: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  articleEditor: {
    articleWorkflowEdit: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    workflow: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    }
  },
  articleReviewer: {
    articleWorkflowReview: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  articleApprover: {
    articleWorkflowApprove: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  commeditor: {
    comm: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  commvisitor: {
    comm: {
      'read:any': ['*']
    }
  },
  controleditor: {
    control: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    controlrisk: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    controlstatus: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    controltheme: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  controlvisitor: {
    control: {
      'read:any': ['*']
    },
    controlrisk: {
      'read:any': ['*']
    },
    controlstatus: {
      'read:any': ['*']
    },
    controltheme: {
      'read:any': ['*']
    },
    riskcomponent: {
      'read:any': ['*']
    }
  },
  faqsvisitor: {
    faq: {
      'read:any': ['*']
    }
  },
  faqseditor: {
    faq: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  faqsapprover: {
    faq: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    faqWorkflowApprove: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  impactadmin: {
    assurance: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    impactstatus: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    impacttopic: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    reportingframework: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    reportingfrequency: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  impactapprover: {
    initiativeWorkflowApprove: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  impacteditor: {
    impactexpert: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    impactinitiative: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    impactobligation: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  impactreporter: {
    impactreport: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  insightsvisitor: {
    article: {
      'read:any': ['*']
    }
  },
  mareditor: {
    assetclass: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    case: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    coverage: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    extsource: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    marrisk: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    person: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    persontype: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    producttype: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  marvisitor: {
    assetclass: {
      'read:any': ['*']
    },
    case: {
      'read:any': ['*']
    },
    coverage: {
      'read:any': ['*']
    },
    extsource: {
      'read:any': ['*']
    },
    marrisk: {
      'read:any': ['*']
    },
    person: {
      'read:any': ['*']
    },
    persontype: {
      'read:any': ['*']
    },
    producttype: {
      'read:any': ['*']
    }
  },
  nfrvisitor: {
    nfrsection: {
      'read:any': ['*']
    }
  },
  obligationsadmin: {
    regulation: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    }
  },
  obligationsvisitor: {
    obligation: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    regulation: {
      'read:any': ['*']
    },
    riskcomponent: {
      'read:any': ['*']
    }
  },
  obligationsapprover: {
    obligationWorkflowApprove: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  riccadmin: {
    publication: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    },
    regcategory: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    regtopicgroup: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    stats: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    },
    tag: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  riccmember: {
    analytics: {
      'read:any': ['*']
    },
    article: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    event: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    newstag: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    publication: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    regulator: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    rricpanel: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    tag: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    },
    tagspanel: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    topic: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    },
    workflow: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*']
    }
  },
  riccmemberbasic: {
    topicUpdate: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  riskcomponentadmin: {
    riskcomponent: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  superadmin: {
    adminMenu: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    adminNotifications: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    appimporting: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    faqproject: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    migration: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    project: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    regulation: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    relationship: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    source: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    topic: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    ugroup: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    user: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    userGroup: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  tagger: {
    newstag: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    },
    tagspanel: {
      'create:any': ['*'],
      'read:any': ['*'],
      'update:any': ['*'],
      'delete:any': ['*']
    }
  },
  norole: {
  }
}
const ac = new AccessControl(grantsObject)
ac.grant('articleReviewer').extend(['articleEditor'])
ac.grant('articleApprover').extend(['articleReviewer'])
ac.grant('impactadmin').extend(['impacteditor'])
ac.grant('obligationsapprover').extend(['obligationsvisitor'])
ac.grant('obligationsadmin').extend(['obligationsapprover'])
ac.grant('riccmember').extend(['impacteditor'])
ac.grant('riccmember').extend(['riccmemberbasic'])
ac.grant('riccadmin').extend(['riccmember'])
export default ac
